import "$styles/index.scss"
import "$styles/syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

console.info("Bridgetown is loaded!")

if (window.innerWidth < 768) {
  [].slice.call(document.querySelectorAll('[data-bss-disabled-mobile]')).forEach(function (elem) {
    elem.classList.remove('animated');
    elem.removeAttribute('data-bss-hover-animate');
    elem.removeAttribute('data-aos');
    elem.removeAttribute('data-bss-parallax-bg');
    elem.removeAttribute('data-bss-scroll-zoom');
  });
}

document.addEventListener('DOMContentLoaded', function() {

  var hoverAnimationTriggerList = [].slice.call(document.querySelectorAll('[data-bss-hover-animate]'));
  var hoverAnimationList = hoverAnimationTriggerList.forEach(function (hoverAnimationEl) {
    hoverAnimationEl.addEventListener('mouseenter', function(e){ e.target.classList.add('animated', e.target.dataset.bssHoverAnimate) });
    hoverAnimationEl.addEventListener('mouseleave', function(e){ e.target.classList.remove('animated', e.target.dataset.bssHoverAnimate) });
  });
}, false);
